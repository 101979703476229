// Import necessary hooks and components
import { useState, useEffect, useCallback } from "react";
import { Button, Stack, Typography, Modal, Box } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSelfEvalTest, submitMCQCod } from "../../redux/actions/dashboard";
import AlertDialog from "../../components/Dialog";
import CodeEditor from "../../components/Editor/CodeEditor";
import { getApplicant } from "../../redux/actions/applicant";
import { toast } from "react-toastify";
import { hideSidebar, showSidebar } from "../../redux/actions/sidebar";
import useFullscreen from "../../hooks/fullScreenWindow";
const modalPopUpStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  textAlign: 'center',
};

const TestCoding = ({
  getApplicant,
  auth: { user },
  dashboard: { test, testResultCode },
  getAllSelfEvalTest,
  submitMCQCod,
  skillData,
  hideSidebar
}) => {
  const navigate = useNavigate();
  const [answerSheetCoding, setAnswerSheetCoding] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [isPassing, setIsPassing] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const { isFullScreen, enterFullScreen } = useFullscreen(); // Use the custom hook

  const handleAnswerSelection = (q_id, answer) => {
    setAnswerSheetCoding((prev) => {
      const updatedAnswers = prev.some((ans) => ans.q_id === q_id)
        ? prev.map((ans) => (ans.q_id === q_id ? { ...ans, answer } : ans))
        : [...prev, { q_id, answer }];
      return updatedAnswers;
    });
  };

  useEffect(() => {
    if (testResultCode) {
      navigate('/candidate/dashboard', { replace: true });
      window.location.reload();
    }
  }, [testResultCode]);

  const handleCodeChange = useCallback((code, q_id, ques) => {
    setAnswerSheetCoding((prev) => {
      const index = prev.findIndex((item) => item.q_id === q_id);
      const updatedSheet = index > -1
        ? prev.map((item, i) => (i === index ? { q_id, answer: code, ques } : item))
        : [...prev, { q_id, answer: code, ques }];
      return updatedSheet;
    });
  }, []);

  useEffect(() => {
    if (skillData?.length) {
      getAllSelfEvalTest({ skills: skillData }, "coding");
    }
  }, [skillData, getAllSelfEvalTest]);

  useEffect(() => {
    console.log();
    if (confirmSubmit) {
      const allAnswers = test.map((q) => {
        const answer = answerSheetCoding.find((ans) => ans.q_id === q.q_id);
        // return { q_id: q.q_id, answer: answer ? answer.answer : null};
        return { 
          q_id: q.q_id, 
          answer: answer ? answer.answer : null, 
          ques: answer ? answer.ques : q.q_name // Including the ques key
        };
      });
      submitMCQCod({ skills: skillData, answerSheetCoding: allAnswers });
      setDialogOpen(false);
      setConfirmSubmit(false);
    }
  }, [confirmSubmit, skillData, answerSheetCoding, submitMCQCod, test]);

  useEffect(() => {
    if (user?.u_apct_id_fk) {
      getApplicant(user.u_apct_id_fk);
    }
  }, [user, getApplicant]);


  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prev) => Math.min(prev + 1, test.length - 1));
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleSubmitConfirmation = () => {
    const attemptedCount = answerSheetCoding.filter((ans) => ans.answer).length;
    setDialogTitle("Submit Test");
    setDialogContent(
      `You have attempted ${attemptedCount} out of ${test.length} questions. Are you sure you want to submit?`
    );
    setDialogOpen(true);
  };
  
  useEffect(() => {
    hideSidebar();
  }, []);
  

  const handleFinalSubmit = () => {
    setOpenModal(false);
    navigate('/candidate/dashboard', { replace: true });
  };


  useEffect(() => {
    const handleCopy = (e) => {
      e.preventDefault();
      if (e.clipboardData) {
        e.clipboardData.clearData();
      } else if (window.clipboardData) { // For IE
        window.clipboardData.setData('text', '');
      }
      toast.error("Copying is not allowed");
    };

    const handleCut = (e) => {
      e.preventDefault();
      if (e.clipboardData) {
        e.clipboardData.clearData();
      } else if (window.clipboardData) { // For IE
        window.clipboardData.setData('text', '');
      }
      toast.error("Cutting is not allowed");
    };

    const handlePaste = (e) => {
      console.log("paste done")
      e.preventDefault();
      toast.error("Pasting is not allowed");
    };

    let codingPage = document.getElementById("codingPage")

    codingPage.addEventListener('copy', handleCopy);
    codingPage.addEventListener('cut', handleCut);
    codingPage.addEventListener('paste', handlePaste);

    // Cleanup event listeners on component unmount
    return () => {
      codingPage.removeEventListener('copy', handleCopy);
      codingPage.removeEventListener('cut', handleCut);
      codingPage.removeEventListener('paste', handlePaste);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
          toast.error("Switching Screen is not allowed");
          setShowWarning(true);
      } else {
          setShowWarning(false);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if the Control key is pressed along with 'C', 'X', or 'V'
      if (e.ctrlKey && (e.key === 'c' || e.key === 'x' || e.key === 'v')) {
        e.preventDefault();
        // alert(`The use of Ctrl + ${e.key.toUpperCase()} is disabled.`);
        let obj = {"c" : "Copying", "x" : "Cutting", "v" : "Pasting"}
        toast.error(`${obj[e.key]} is not allowed`);
      }
    };

    let codingPage = document.getElementById("codingPage")

    codingPage.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      codingPage.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      toast.error("Action Not Allowed");
    };

    document.addEventListener('contextmenu', handleContextMenu);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleMouseOut = (event) => {
      if (!event.relatedTarget) {
        // The mouse has left the window
        toast.error("You cannot leave the window");
      }
    };

    window.addEventListener('mouseout', handleMouseOut);

    return () => {
      window.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Disable Escape, F12, and Alt+F4 keys
      if (
        event.key === 'F12' ||
        (event.altKey && event.key === 'F4') ||
        event.key === 'Escape'
      ) {
        event.preventDefault(); // Prevent default actions (dev tools, closing window, etc.)
        toast.error("Invalid Action");
        // enterFullScreen();
      }
  
      // Disable Ctrl+C, Ctrl+X, Ctrl+V
      if (event.ctrlKey && (event.key === 'c' || event.key === 'x' || event.key === 'v')) {
        event.preventDefault();
        const actions = { c: 'Copying', x: 'Cutting', v: 'Pasting' };
        toast.error(`${actions[event.key]} is not allowed`);
      }
    };
  
    // Attach event listener for keydown
    window.addEventListener('keydown', handleKeyDown);
  
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);  

  // useEffect(() => {
  //   // Automatically click the button on load
  //   const button = document.getElementById("enterFullScreenButton");
  //   if (button) {
  //     enterFullScreen();
  //   }
  // }, []);

  return (
    <Stack direction="column" alignItems="center" id="codingPage">
       {!isFullScreen && (
        <Button id="enterFullScreenButton" variant="contained" onClick={enterFullScreen}>
          Enter Full Screen
        </Button>
      )}
      <AlertDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={dialogTitle}
        setConfirm={setConfirmSubmit}
        content={dialogContent}
      />
      <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
        <Button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
          Previous
        </Button>
        <Button onClick={handleNextQuestion} disabled={currentQuestionIndex === test?.length - 1}>
          Next
        </Button>
        <Button onClick={handleSubmitConfirmation}>Submit</Button>
        <Button onClick={() => navigate("/dashboard")}>Back</Button>
      </Stack>

      <Typography variant="h6" sx={{ textAlign: "center", mt: 3 }}>
        Test Coding
      </Typography>

      {test?.length > 0 && (
        <Stack direction="column" alignSelf="center" spacing={1} sx={{ pt: 2, width: "100%", userSelect: 'none'  }}>
          <Typography variant="h6">
            {currentQuestionIndex + 1}. {test[currentQuestionIndex].q_name}
          </Typography>
          <CodeEditor
            key={test[currentQuestionIndex].q_id}
            onChange={(e) =>
              handleCodeChange(e, test[currentQuestionIndex].q_id, test[currentQuestionIndex].q_name)
            }
            style={{ width: "100%", userSelect: 'none' }}
          />
        </Stack>
      )}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="modal-title">
            Your Score is
          </Typography>
          <Typography>Your code is under review, you can check on the dashboard.</Typography>
          <Button variant="contained" color={isPassing ? "success" : "error"} onClick={handleFinalSubmit}>
            OK
          </Button>
        </Box>
      </Modal>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userData: state.auth.userData,
  dashboard: state.dashboard,
  skillData: state.applicant?.applicant?.skills,
  testResult: state.dashboard.testResult,
});

const mapDispatchToProps = {
  getAllSelfEvalTest,
  submitMCQCod,
  getApplicant,
  hideSidebar
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCoding);
