import axios from "axios";

export const _getAllAclGroups = async () => {
  try {
    const url = `/acl/get-all-acl-groups`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getUserList = async (grpId) => {
  try {
    const url = `/acl/get-acl-users-by-groupname/${grpId}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};


export const savePermissionsApi = async (data) => {
  try {
    const url = `/acl/update-acl-group/${data.selectedUser}`;
    const response = await axios.put(url, data);
    return response;

  } catch (error) {
    throw error;
  }
};

export const getPermissionListApi = async () => {
  try {
    const url = `/acl/get-all`;
    const response = await axios.get(url);
    return response;

  } catch (error) {
    throw error;
  }
};


export const addModulePermissionApi = async (data, editItemId) => {
  try {
    const url = `/acl/save-acl`;
    const response = await axios.post(url, { data, editItemId });
    return response;
  } catch (error) {
    throw error;
  }
};