import { useState, useEffect, useRef } from "react";
import {
  Button,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  CardContent,
  Card,
  Box,
  Modal,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSelfEvalTest, submitMCQ } from "../../redux/actions/dashboard";
import AlertDialog from "../../components/Dialog";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApplicant } from "../../redux/actions/applicant";
import { hideSidebar, showSidebar } from "../../redux/actions/sidebar";
import useFullscreen from "../../hooks/fullScreenWindow";
const modalPopUpStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  textAlign: 'center',
};

const percentageStyle = (isPassing) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: '100px',
  height: '100px',
  backgroundColor: isPassing ? 'green' : 'red',
  color: 'white',
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '20px 0',
});

export const TestPage = ({
  getApplicant,
  userData,
  auth: { user },
  dashboard: { test },
  getAllSelfEvalTest,
  submitMCQ,
  testResult,
  skillData,
  hideSidebar
}) => {
  const navigate = useNavigate();
  const [answerSheet, setAnswerSheet] = useState([]);
  const [openSecond, setOpenSecond] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [isPassing, setIsPassing] = useState(0);
  const [returnOfSubmit, setReturnOfSubmit] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const { isFullScreen, enterFullScreen } = useFullscreen(); // Use the custom hook


  const toggleModalOpen = () => {
    setOpenSecond(true);
  };

  const onClickAnswer = (e, q_id, opt) => {
    if (answerSheet.find((ans) => ans.q_id === q_id)) {
      if (answerSheet.find((ans) => ans.q_id === q_id && ans.answer === opt)) {
        let tempArray = answerSheet.filter((ans) => ans.q_id !== q_id);
        setAnswerSheet(tempArray);
      } else {
        let tempArray = answerSheet.map((ans) => {
          return ans.q_id === q_id ? { ...ans, answer: opt } : ans;
        });
        setAnswerSheet(tempArray);
      }
    } else {
      let obj = {
        q_id: q_id,
        answer: opt,
      };
      setAnswerSheet([...answerSheet, obj]);
    }
  };

  useEffect(() => {
    if (skillData && skillData?.length) {
      getAllSelfEvalTest({ skills: skillData }, "mcq");
    }
  }, [skillData]);

  useEffect(() => {
    if (confirm) {
      submitFinalData();
    }
  }, [confirm]);

  useEffect(() => {
    hideSidebar();
  }, []);

  useEffect(() => {
    const handleCopy = (e) => {
      e.preventDefault();
      if (e.clipboardData) {
        e.clipboardData.clearData();
      } else if (window.clipboardData) {
        window.clipboardData.setData('text', '');
      }
      toast.error("Copying is not allowed");
    };

    const handleCut = (e) => {
      e.preventDefault();
      if (e.clipboardData) {
        e.clipboardData.clearData();
      } else if (window.clipboardData) {
        window.clipboardData.setData('text', '');
      }
      toast.error("Cutting is not allowed");
    };

    const handlePaste = (e) => {
      e.preventDefault();
      toast.error("Pasting is not allowed");
    };

    let mcqsPage = document.getElementById("mcqPage");

    mcqsPage.addEventListener('copy', handleCopy);
    mcqsPage.addEventListener('cut', handleCut);
    mcqsPage.addEventListener('paste', handlePaste);

    return () => {
      mcqsPage.removeEventListener('copy', handleCopy);
      mcqsPage.removeEventListener('cut', handleCut);
      mcqsPage.removeEventListener('paste', handlePaste);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === 'c' || e.key === 'x' || e.key === 'v')) {
        e.preventDefault();
        let obj = { "c": "Copying", "x": "Cutting", "v": "Pasting" };
        toast.error(`${obj[e.key]} is not allowed`);
      }
    };

    let mcqPage = document.getElementById("mcqPage");

    mcqPage.addEventListener('keydown', handleKeyDown);

    return () => {
      mcqPage.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      toast.error("Action Not Allowed");
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        toast.error("Switching Screen is not allowed");
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Updated useEffect to handle mouse leave event
  useEffect(() => {
    const handleMouseOut = (event) => {
      if (!event.relatedTarget) {
        // The mouse has left the window
        toast.error("You cannot leave the window");
      }
    };

    window.addEventListener('mouseout', handleMouseOut);

    return () => {
      window.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  const submitFinalData = async () => {
    let data = {
      skills: skillData,
      totalQues: test?.length,
      answerSheetMCQ: answerSheet,
    };
    await submitMCQ(data);
    setOpen(false);
    setConfirm(false);
    navigate('/candidate/dashboard', { replace: true });
    window.location.reload();
  };

  const submitData = () => {
    setTitle("Submit Test");
    setContent("Are you sure you want to submit the test?");
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpenSecond(false);
    navigate('/employee/self-assessment/coding', { replace: true });
  }

  useEffect(() => {
    if (user && user.u_apct_id_fk) {
      getApplicant(user.u_apct_id_fk);
    }
  }, [user]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if F12 or Alt + F4 is pressed
      if (event.key === 'F12' || (event.altKey && event.key === 'F4' || event.key === 'Escape' || event.keyCode === 27)) {
        event.preventDefault(); // Prevent the default action (e.g., opening the dev tools or closing the window)
        toast.error("Invalid Action");
      }
    };

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      {!isFullScreen && (
        <Button id="enterFullScreenButton" variant="contained" onClick={enterFullScreen}>
          Enter Full Screen
        </Button>
      )}
      <CardContent id="mcqPage">
        <Stack direction="column" alignItems="center" spacing={3}>
          <Box component="main" sx={{ width: "100%" }}>
            {returnOfSubmit && <h1>{returnOfSubmit.finalPercentage}</h1>}
            <AlertDialog
              open={open}
              setOpen={setOpen}
              title={title}
              setConfirm={setConfirm}
              content={content}
            />
            <Stack
              direction={{ xs: "column", sm: "column" }}
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Stack direction="column">
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      MCQ Test
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
              {test &&
                test?.length > 0 &&
                test.map((item, index) => (
                  <Card key={index} sx={{ mb: 2, userSelect: 'none' }}>
                    <CardContent>
                      <Typography variant="h6">
                        {index + 1}. {item.q_name}
                      </Typography>
                      <Stack direction="column">
                        {item?.options?.map((opt, idx) => (
                          <FormControlLabel
                            key={opt.a_id}
                            control={
                              <Checkbox
                                color="primary"
                                checked={
                                  answerSheet &&
                                  answerSheet.find(
                                    (ans) =>
                                      ans.q_id === item.q_id &&
                                      ans.answer === opt.a_label
                                  )
                                    ? true
                                    : false
                                }
                              />
                            }
                            label={opt.a_text}
                            onClick={(e) =>
                              onClickAnswer(e, item.q_id, opt.a_label)
                            }
                          />
                        ))}
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Stack direction="column">
                    <Box component="footer" sx={{ width: "100%" }}>
                      <Stack
                        direction="row"
                        sx={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          color="primary"
                          component="label"
                          variant="contained"
                          tabIndex={-1}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </Button>
                        <Button
                          color="primary"
                          component="label"
                          variant="contained"
                          tabIndex={-1}
                          onClick={submitData}
                          disabled={answerSheet?.length === 0}
                        >
                          Submit
                        </Button>
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Box>
        </Stack>
      </CardContent>
      <Modal
        open={openSecond}
        onClose={() => {}}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="child-modal-title">
            Your Score is
          </Typography>
          {testResult && (
            <>
              <Box sx={percentageStyle(isPassing)}>
                {testResult?.finalPercentage}%
              </Box>
              <Typography>Total Question: 20</Typography>
              <Typography>
                Total Attempted Question :{" "}
                {testResult?.totalAttemptedFinale / skillData?.length}
              </Typography>
              <Typography>
                Total Correct Question: {testResult?.totalCorrect}
              </Typography>
            </>
          )}
          <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color={isPassing ? "success" : "error"}
              onClick={handleSubmit}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userData: state.auth.userData,
  dashboard: state.dashboard,
  skillData: state.applicant?.applicant?.skills,
  testResult: state.dashboard.testResult,
});

const mapDispatchToProps = {
  getAllSelfEvalTest,
  submitMCQ,
  getApplicant,
  hideSidebar
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
