import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const EnsureOrgNameInRoute = ({ orgName, children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;

        // Check if 'org_name' is in the route
        if (!currentPath.includes(orgName)) {
            // If 'org_name' is missing, prepend it to the route and navigate
            const newPath = `/${orgName}${currentPath}`;
            navigate(newPath, { replace: true });
        }
    }, [location, navigate, orgName]);

    return children; // Render the children only if the route is correct
};

export default EnsureOrgNameInRoute;
