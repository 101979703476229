import { useState, useEffect } from "react";
import { Typography, Card, CardContent, Divider, Stack, Button, Box, } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import { getAllLabels, getAllLabelOptions, saveOrganizationLabel } from "../../redux/actions/label";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

export const Label = ({ getAllLabels, labels, getAllLabelOptions, label_options, saveOrganizationLabel }) => {
  const [rows, setRows] = useState([]);
  const [editingRow, setEditingRow] = useState();
  const [editingInput, setEditingInput] = useState();
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
  });
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

  useEffect(() => {
    getAllLabels();
    getAllLabelOptions(paginationModals.page, paginationModals.pageSize)
  }, [paginationModals, getAllLabelOptions]);
  useEffect(() => {
    updateTableHeight(label_options?.length);
  }, [paginationModals.pageSize, label_options]);
  useEffect(() => {
    setRows(label_options);
  }, [label_options]);

  const handleEditCustomValue = (id) => {
    setEditingRow(id);
    setEditingInput()
  }
  const handleSaveCustomValue = (label_id) => {
    saveOrganizationLabel({ label_id, custom_value: editingInput }).then(res => {
      setEditingRow();
    })
  }

  const columns = [
    {
      field: "lb_key",
      headerName: "Default Key",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "lb_value",
      headerName: "Default Value",
      flex: 1,
    },
    {
      field: "dtret",
      headerName: "Custom Value",
      renderCell: ({ row }) => (labels  && <>
        {row.lb_id == editingRow ? (<input style={{ width: '100%' }} onKeyDown={(event) => event.stopPropagation()} onChange={(e) => setEditingInput(e.target.value)} defaultValue={labels.find(item => item.ol_lb_id_fk == row.lb_id)?.ol_value} />) : labels.find(item => item.ol_lb_id_fk == row.lb_id)?.ol_value}
      </>),
      flex: 1,
      // editable: true,
      // valueGetter: (value, row) => (labels.find(item => item.ol_lb_id_fk == row.lb_id)?.ol_value),
      // valueSetter: (value, row) => {
      //   const labelIndex = labels.findIndex((item) => item.ol_lb_id_fk === row.lb_id);
      //   if (labelIndex >= 0) {
      //     labels[labelIndex].ol_value = value;
      //   }
      //   return row
      // }
    },
    {
      field: "sl_page",
      headerName: "Page",
      flex: 1,
    },
    {
      field: "sl_internal_name",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => (row.lb_id !== editingRow ? <Button onClick={() => handleEditCustomValue(row.lb_id)}>
        <EditIcon />
      </Button> : (<Button disabled={!editingInput} onClick={() => handleSaveCustomValue(row.lb_id)}>
        <SaveIcon />
      </Button>))
    },
  ];
  useEffect(() => {
    getAllLabelOptions(paginationModals.page, paginationModals.pageSize);
  }, [paginationModals, getAllLabelOptions]);

  return (
    <>
      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5"> Label</Typography>
            {/* <Button
              variant="text"
              size={"small"}
              onClick={() => addLabel()}
              sx={{ color: "green" }}
            >
              <AddIcon /> Add
            </Button> */}
          </Box>
          <Divider />
          <div style={{ height: tableHeight, width: "100%" }}>
            {label_options && <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={label_options && label_options?.rows}
              getRowId={(row) => row?.lb_id}
              total={rows?.count}
              columns={columns}
            />}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  labels: state.labels.allLabels,
  label_options: state.labels.allLabelOptions,
});

const mapDispatchToProps = { getAllLabels, getAllLabelOptions, saveOrganizationLabel };

export default connect(mapStateToProps, mapDispatchToProps)(Label);

