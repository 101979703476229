import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ContactMail from "@mui/icons-material/ContactMail";
import { Button, FormControl, FormLabel, Modal, Radio, RadioGroup, } from "@mui/material"
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { modalPopUpStyle } from '../styles/getLPTheme';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";
import { logoutUser } from "../redux/actions/auth";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { io } from "socket.io-client";
import moment from "moment";
import "moment-timezone";
import { Switch, FormControlLabel } from "@mui/material";
import { getSocketProgress } from "../redux/actions/progress";
import { sendInvite } from "../redux/actions/auth";
import { useTranslation } from "react-i18next";

let BASE_URL = localStorage.getItem('REACT_APP_WS_URL') || process.env.REACT_APP_WS_URL;
let orgName = localStorage.getItem('org_name');

const socket = io(BASE_URL, {
  path: `/${orgName}/socket.io`,
  transports: ["websocket", "polling"],
});
const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);


function ToggleColorMode({ mode, toggleColorMode }) {
  return (
    <Box sx={{ maxWidth: "32px" }}>
      <IconButton
        size="large"
        color="inherit"
        variant="text"
        onClick={toggleColorMode}
        aria-label="button to toggle theme"
        sx={{ minWidth: "32px", height: "32px", p: "4px" }}
      >
        {mode === "dark" ? (
          <WbSunnyRoundedIcon fontSize="small" />
        ) : (
          <ModeNightRoundedIcon fontSize="small" />
        )}
      </IconButton>
    </Box>
  );
}

const drawerWidth = 230;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Option(props) {
  return null;
}

Option.propTypes = {
  value: PropTypes.number,
  children: PropTypes.node,
};
const Header = ({
  mode,
  toggleColorMode,
  open,
  handleDrawerOpen,
  logoutUser,
  currentTheme,
  role,
  getSocketProgress,
  sendInvite,
  isShowSidebar,
  signupInvite
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAct, setAnchorElAct] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [userName, setUserName] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [activities, setActivities] = useState([]);
  const [userTimezone, setUserTimezone] = useState(null);
  const uname = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))["u_name"]
    : "";

  const user = JSON.parse(localStorage.getItem("user"));

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [openModal, setOpenModal] = useState(false)
  const [inviteFormData, setInviteFormData] = useState()

  const handleSendInvite = () => {
    sendInvite(inviteFormData)
  };

  useEffect(() => {
    if (signupInvite && signupInvite.status === 200) {
      setOpenModal(false)
    }
  }, [signupInvite])

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "emails" && !value) {
      return;
    } else {
      setInviteFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (name === "user_type" && !value) {
      return;
    } else {
      setInviteFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


  // useEffect(() => {
  //   socket.on("to_client", (data) => {
  //     console.log("data : 98", data);
  //     if (data.progress === 100) {
  //       setActivities(activities.filter((act) => act.jd_id !== data.jd_id));
  //     } else {
  //       if (activities.some((act) => act.jd_id === data.jd_id)) {
  //         setActivities(
  //           activities.map((act) => (act.jd_id === data.jd_id ? data : act))
  //         );
  //       } else {
  //         setActivities([...activities, data]);
  //       }
  //     }
  //   });
  // }, []);

  useEffect(() => {
    socket.on("to_client", (data) => {
      // console.log("data : 98", data);
      let newActivities;
      if (data.progress === 100) {
        newActivities = activities.filter((act) => act.jd_id !== data.jd_id);
      } else {
        if (activities.some((act) => act.jd_id === data.jd_id)) {
          newActivities = activities.map((act) =>
            act.jd_id === data.jd_id ? data : act
          );
        } else {
          newActivities = [...activities, data];
        }
      }
      setActivities(newActivities);
      getSocketProgress(newActivities);
    });
  }, [activities, getSocketProgress]);


  useEffect(() => {
    if (user) {
      setImageUrl(user.u_avatar);
      setUserName(user.u_name);
      setUserTimezone(user.u_timezone);
    }
  }, [user]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAct = (event) => {
    setAnchorElAct(event.currentTarget);
  };

  const handleCloseAct = () => {
    setAnchorElAct(null);
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("org_name");
    logoutUser();
    window.location.assign("/signin");
  };

  const [greeting, setGreeting] = useState("Hello");

  useEffect(() => {
    const timezone = userTimezone || moment.tz.guess() || "UTC";
    const currentTime = moment.tz(timezone).hours();

    if (currentTime >= 4 && currentTime < 12) {
      setGreeting(t('Good Morning'));
    } else if (currentTime >= 12 && currentTime < 17) {
      setGreeting(t("Good Afternoon"));
      // } else if (currentTime >= 17 && currentTime < 21){
      //   setGreeting("Good Evening");
    } else {
      setGreeting(t('Good Evening'));
    }
  }, [userTimezone, t]);

  return (
    <AppBar position="fixed" open={open} sx={{ ...(!isShowSidebar && { width: "98%" }) }}>
      <Toolbar
        variant="regular"
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexShrink: 0,
          // backdropFilter: "blur(24px)",
        })}
      >
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            flexGrow: 1,
          }}
        >
          <Typography variant="h6" color={"grey"}>
            {greeting}, {uname}{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 0,
          }}
        >
          {/* Language switcher */}
          {/* <FormControlLabel
          control={
            <Switch
              checked={language === 'fr'}
              onChange={handleLanguageChange}
              color="primary"
            />
          }
           label={
            <Typography 
              variant="body2" 
              sx={{ color: language === 'fr' ? 'blue' : 'red' }} 
            >
              {language === 'fr' ? 'FR' : 'EN'}
            </Typography>
          }
          sx={{ ml: 2 }}
        /> */}
          {/* {activities.length > 0 && (
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuAct}
                color="inherit"
              >
                <Badge badgeContent={activities.length} color="secondary">
                  <PublishedWithChangesIcon />
                </Badge>
              </IconButton>
              <Menu
                id="menu-appbar-status"
                anchorEl={anchorElAct}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElAct)}
                onClose={handleCloseAct}
                PaperProps={{ sx: { width: "300px" } }}
              >
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  justifyContent="space-between"
                  sx={{ padding: "10px" }}
                >
                  <Typography variant="body1" color="black">
                    Batch
                  </Typography>
                  <Typography variant="body1" color="black">
                    Progress
                  </Typography>
                </Stack>
                {activities.map((act) => (
                  <Stack
                    key={act.jd_id}
                    direction={{ xs: "row", sm: "row" }}
                    justifyContent="space-between"
                    sx={{ padding: "10px" }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "11rem",
                      }}
                    >
                      <Typography noWrap variant="body1" color="primary">
                        {act.bt_name} :
                      </Typography>
                    </div>
                    <Typography variant="body1" color="primary">
                      {act && act.progress ? act.progress.toFixed(2) : 0}%
                    </Typography>
                  </Stack>
                ))}
              </Menu>
            </>
          )} */}
          {(role === 'hr') && (<IconButton title="Send Invite" sx={{ mx: 2 }} onClick={() => setOpenModal(true)} >
            <ContactMail />
          </IconButton>)}
          <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />

          <>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {/* <AccountCircle /> */}
              <Avatar
                sx={{ width: 30, height: 30 }}
                alt={userName}
                src={`${localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL}/${localStorage.getItem('org_name')}/api/v1user/image?token=${token}&timestamp=${Date.now()}`}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Avatar
                    sx={{ width: 40, height: 40, marginBottom: "10px" }}
                    alt={userName}
                    src={`${localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL}/user/image?token=${token}&timestamp=${Date.now()}`}
                  />
                  <Typography variant="h6" color={"grey"}>
                    {uname}
                  </Typography>
                </Box>

                <MenuItem>
                  <Link to={`/${role}/profile`} onClick={handleClose}>Profile</Link>
                </MenuItem>
                {/* <MenuItem >Account</MenuItem> */}
                {/* <MenuItem>
                  <Link to={`/${role}/setting`}>Setting</Link>
                </MenuItem> */}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </div>
            </Menu>
          </>
        </Box>
      </Toolbar>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box className="modalContent" sx={modalPopUpStyle}
          component="form">
          <h2 className="modalTitle" id="child-modal-title">
            Send invite
          </h2>
          <form className="modalForm">
            <div className="modalFormItem">
              <label className="label-bold">Email(s)</label>
              <Textarea name="emails" onChange={handleFormDataChange}></Textarea>
            </div>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Select</FormLabel>
              <RadioGroup onChange={handleFormDataChange}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="user_type"
              >
                <FormControlLabel value="hr" control={<Radio />} label="HR" />
                <FormControlLabel value="employee" control={<Radio />} label="Employee" />
              </RadioGroup>
            </FormControl>
            <div className="modalActions">
              <Button className="modalButton" onClick={handleSendInvite} >
                Send
              </Button>
              <Button className="modalButton" onClick={() => setOpenModal(false)}>
                Cancel
              </Button>
            </div>
          </form>

        </Box>
      </Modal>

    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  currentTheme: state.dashboard.currentTheme,
  progress: state.progress.progress,
  signupInvite: state.auth.signupInvite,
});

const mapDispatchToProps = { logoutUser, getSocketProgress, sendInvite };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
