import React, { useEffect, useRef, useState } from "react";
import { IconButton, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { io } from "socket.io-client";
import { createAudioVideo } from "../../redux/actions/jobs";
import { connect } from "react-redux";
import * as faceapi from "face-api.js";
import { _checkTransCription } from "../../services/applicant.service";

let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem('org_name');
let API_URL = '';
if (orgName) {
    API_URL = `${BASE_URL}/${orgName}`
} else {
    API_URL = `${BASE_URL}`
}
const socket = io(BASE_URL, {
  path: `/${orgName}/socket.io`,  // Adjust the path if necessary
});

const constraints = {
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
  },
  video: {
    width: 640,
    height: 480,
    aspectRatio: 1.7777777778,
    frameRate: { ideal: 10, max: 20 },
    facingMode: "user",
  },
};

const WebcamVideo = ({ threadId, userId, setAudio, qaId, intwId, startCapturing }) => {
  const [permission, setPermission] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [countdown, setCountdown] = useState(30); // Countdown timer for popup
  const mediaRecorder = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const videoRecorder = useRef(null);
  const audioRecorder = useRef(null);
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [abortController, setAbortController] = useState(null);
  
  let audioChunks = useRef([]); // Persist audioChunks with useRef to maintain reference across intervals
  const countdownInterval = useRef(null); // Persist the countdown interval across renders
  
  let transcriptionInterval;

  const cancelOngoingAPI = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
  };

  const loadFaceDetectionModels = async () => {
    const MODEL_URL = process.env.PUBLIC_URL + "/models";
    await Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
      faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
      faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    ]);
    console.log("FaceAPI models loaded");
  };

  const handleVideoOnPlay = () => {
    const intervalId = setInterval(async () => {
      if (canvasRef.current && videoRef.current.readyState === 4) {
        const displaySize = { width: 640, height: 480 };
        faceapi.matchDimensions(canvasRef.current, displaySize);

        const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks()
          .withFaceExpressions();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  };

  const analyzeAudio = async (audioBlob) => {
    try {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const arrayBuffer = await audioBlob.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const channelData = audioBuffer.getChannelData(0);

      const threshold = 0.02;
      const result = channelData.some(sample => Math.abs(sample) > threshold);
      console.log("Audio analyzed, result:", result);
      return result;
    } catch (error) {
      console.error("Error decoding audio data:", error);
      return false;
    }
  };

  const startTranscriptionInterval = () => {
    transcriptionInterval = setInterval(async () => {
      if (audioChunks.current.length > 0) {
        const last5SecAudio = new Blob(audioChunks.current, { type: "audio/webm" });
        const audioPresent = await analyzeAudio(last5SecAudio);
        if (!audioPresent) {
          console.log("No audio detected, opening popup");
          showPopup();
        }
        audioChunks.current.length = 0; // Clear the chunks after processing
      } else {
        console.log("No audio chunks available to analyze");
      }
    }, 15000);
  };

  const stopTranscriptionInterval = () => {
    clearInterval(transcriptionInterval);
  };

  const startVideoStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      setVideoStream(stream);

      const videoMediaRecorder = new MediaRecorder(stream);
      videoRecorder.current = videoMediaRecorder;
      videoMediaRecorder.ondataavailable = (event) => {
        socket.emit("video-stream", {
          data: event.data,
          vdUserId: userId,
          streamId: qaId,
        });
      };
      videoMediaRecorder.start(100);

      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(audioStream);

      const audioMediaRecorder = new MediaRecorder(audioStream);
      audioRecorder.current = audioMediaRecorder;

      audioMediaRecorder.ondataavailable = (event) => {
        socket.emit("audio-stream", { data: event.data, vdUserId: userId, streamId: qaId });
        audioChunks.current.push(event.data);

        // Keep only the last 10 seconds of chunks
        const maxDurationMs = 10000; // 10 seconds
        const chunkDurationMs = 100; // assuming each chunk is 100ms
        const maxChunks = Math.ceil(maxDurationMs / chunkDurationMs); // number of chunks to keep

        if (audioChunks.current.length > maxChunks) {
          // Remove the oldest chunks to keep the buffer size within the last 10 seconds
          audioChunks.current.shift();
        }
      };

      audioMediaRecorder.start(100);

      console.log("Starting transcription interval");
      startTranscriptionInterval(); // Start transcription interval when recording starts
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  };

  const stopRecording = () => {
    videoRecorder.current?.stop();
    audioRecorder.current?.stop();
    videoStream?.getTracks().forEach(track => track.stop());
    audioStream?.getTracks().forEach(track => track.stop());
    stopTranscriptionInterval(); // Stop the transcription interval when recording stops
    cancelOngoingAPI();
    setAudio(qaId);
    setCapturing(false);
  };

  const getCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia(constraints);
      setPermission(true);
    } catch (err) {
      alert("Error accessing camera/microphone");
      console.error("Camera permission error:", err);
    }
  };

  const showPopup = () => {
    setOpenPopup(true);
    resetCountdown(); // Reset and start the countdown when the popup is shown
    stopTranscriptionInterval(); // Stop the interval when popup opens
  };

  const resetCountdown = () => {
    setCountdown(30); // Reset the countdown timer
    if (countdownInterval.current) clearInterval(countdownInterval.current); // Clear any existing interval
    countdownInterval.current = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          clearInterval(countdownInterval.current); // Stop the countdown when time's up
          console.log("Auto-submitting due to timeout");
          handlePopupClose(true); // Auto-submit if timer reaches 0
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handlePopupClose = (submit) => {
    if (countdownInterval.current) clearInterval(countdownInterval.current); // Stop the countdown interval
    setOpenPopup(false);
    stopTranscriptionInterval();
    if (submit) {
      console.log("Stopping recording due to user action or timeout");
      stopRecording();
    } else {
      console.log("User chose to continue, restarting transcription interval");
      startTranscriptionInterval(); // Restart the transcription interval if the user chooses to continue
    }
  };

  useEffect(() => {
    loadFaceDetectionModels();
  }, []);

  useEffect(() => {
    if (startCapturing) {
      startVideoStream();
      setCapturing(true);
    }
  }, [startCapturing]);

  useEffect(() => {
    if (!permission) {
      getCameraPermission();
    }
  }, [permission]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleLoadedData = () => {
        handleVideoOnPlay();
      };
      videoElement.addEventListener('loadeddata', handleLoadedData);
      return () => {
        videoElement.removeEventListener('loadeddata', handleLoadedData);
      };
    }
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", minWidth: "100%" }}>
      <div id="videoContainer" style={{ minWidth: "100%", position: "relative", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <video
          id="video"
          ref={videoRef}
          style={{ width: "100%" }}
          muted
          autoPlay
        />
        <canvas ref={canvasRef} style={{ position: "absolute", top: 0 }} />

        {!permission && (
          <div style={{ textAlign: "center", marginTop: 10 }}>
            <IconButton onClick={getCameraPermission}>
              <VideocamOffIcon />
            </IconButton>
            <Typography variant="caption" color="error">
              Please allow permission for camera and mic
            </Typography>
          </div>
        )}

        {permission && (
          <IconButton  style={{ marginTop: 10 }}>
            {capturing ? (
              <StopCircleIcon style={{ height: 60, width: 60, backgroundColor: "red", color: "white", borderRadius: "50%" }} />
            ) : (
              <PlayCircleIcon style={{ height: 60, width: 60, backgroundColor: "green", color: "white", borderRadius: "50%" }} />
            )}
          </IconButton>
        )}

        {/* Popup Dialog */}
        <Dialog
          open={openPopup}
          onClose={() => handlePopupClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"No Response Detected"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              No response was detected from last few seconds. Would you like to submit your answer, or continue?
              <br />
              Auto-submit in {countdown} seconds.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handlePopupClose(false)} color="primary">
              Continue
            </Button>
            <Button onClick={() => handlePopupClose(true)} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  audioVideoList: state.jobs.audioVideoList,
});

const mapDispatchToProps = {
  createAudioVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebcamVideo);
