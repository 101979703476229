import { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getLPTheme from "./styles/getLPTheme";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Header from "./layouts/Header";
import SideBar from "./layouts/SideBar";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import { authUser } from "./redux/actions/auth";
import { toggleThemeAction } from "./redux/actions/dashboard";
import { getAppLabels } from "./redux/actions/app_org_labels";
import { setAuthToken } from "./utils/setAuthToken";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PrivateRoute from "./routes/PrivateRoutes";
import PublicRoute from "./routes/PublicRoutes";
import CandidateRoutes from "./routes/CandidateRoutes";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import initializeI18n from "./i18";
import { useTranslation } from "react-i18next";
import EnsureOrgNameInRoute from "./routes/EnsureOrgNameInRoute";

const App = ({
  auth: { isLoggedIn, user },
  isLoading,
  messages,
  isCandLoggedIn,
  toggleThemeAction,
  isShowSidebar,
  getAppLabels,
  appOrgLabel,
}) => {
  setAuthToken(localStorage.getItem("token"));
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(true);
  const [mode, setMode] = useState("light");
  const LPtheme = createTheme(getLPTheme(mode));
  const [mainLayout, setMainLayout] = useState("");
  const [showSideBar, setShowSideBar] = useState(true);
  const ref = useRef();

  const [pos, setPos] = useState(false);

  const handleScroll = () => {
    let scrollPosition = window.pageYOffset;
    if (scrollPosition > 300) {
      setPos(true);
    } else {
      setPos(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    // Remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(appOrgLabel).length > 0) {
      i18n.addResources('en', 'translation', appOrgLabel);
      i18n.changeLanguage('en'); // Set the default language
    }
  }, [appOrgLabel, i18n]);


  useEffect(() => {
    getAppLabels()
  }, [])

  useEffect(() => {
    if (messages && messages.length > 0) {
      messages.map((msg) => {
        toast(msg.msg);
      });
    }
  }, [messages]);

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    toggleThemeAction(mode);
    document.body.className = `theme-${mode}`;
  }, [mode]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (
    <>
      <ToastContainer />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(16, 16, 16, 0.5)",
            zIndex: 999990,
          }}
        >
          <Spinner
            name="three-bounce"
            style={{
              marginTop: "50vh",
              marginLeft: "50vw",
              zIndex: 999999,
              color: "#0959AA",
            }}
          />
        </div>
      ) : (
        ""
      )}

      <ThemeProvider theme={LPtheme}>
        <CssBaseline />

        <Box sx={{ display: "flex" }} className={"theme-" + mode} ref={ref}>
          <Router>
              {isLoggedIn ? (
                <>
                  <Header
                    mode={mode}
                    toggleColorMode={toggleColorMode}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    role={user && user.u_role}
                    isShowSidebar={isShowSidebar}
                  />
                  <HelmetProvider>
                    <Helmet>
                      <title>L&D : {mainLayout && mainLayout.label}</title>
                    </Helmet>
                  </HelmetProvider>
                </>
              ) : (
                ""
              )}
              {isCandLoggedIn && isCandLoggedIn && <CandidateRoutes />}
              {isLoggedIn && isLoggedIn ? (
                <>
                  {isShowSidebar && <SideBar
                    open={open}
                    handleDrawerClose={handleDrawerClose}
                    setMainLayout={setMainLayout}
                    mainLayout={mainLayout}
                    role={user && user.u_role}
                  />}
                  <EnsureOrgNameInRoute orgName={user && user.org_name}>
                  <Routes>
                    <Route path={"/" + user.org_name + "/*"} element={<PrivateRoute
                      open={open}
                      mode={mode}
                      role={user && user.u_role}
                      breadCrum={mainLayout && mainLayout.breadCrum}
                    />} />
                    <Route path="*" element={<Navigate to={"/" + user.org_name + "/" + user.u_role + "/dashboard"} replace />} />
                  </Routes>
                  </EnsureOrgNameInRoute>
                </>
              ) : (
                <PublicRoute />
              )}
          </Router>
        </Box>
      </ThemeProvider>
      {pos && (
        <IconButton
          sx={{
            position: "fixed",
            bottom: "90px",
            right: "20px",
            zIndex: 9999,
            backgroundColor: "#89CFF0",
          }}
        >
          <KeyboardArrowUpIcon
            color="primary"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            fontSize="large"
          />
        </IconButton>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isLoading: state.loader.isLoading,
  messages: state.message,
  isCandLoggedIn: state.candidate.isCandLoggedIn,
  appOrgLabel: state.app_org_labels.appOrgLabel,
  isShowSidebar: state.sidebar.isSidebar,
});

const mapDispatchToProps = { authUser, toggleThemeAction, getAppLabels };

export default connect(mapStateToProps, mapDispatchToProps)(App);
