import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  TextField,
  IconButton
} from '@mui/material';
import { savePermissions, getAllAclGroups, getPermissionList, createModulePermission, getUserList } from "../../redux/actions/acls"
import { connect } from "react-redux";
import { modalPopUpStyle } from '../../styles/getLPTheme';
import EditIcon from '@mui/icons-material/Edit';

const AccessControlManagement = ({ savePermissions, getAllAclGroups, permissions, userGroups, getPermissionList, createModulePermission, getUserList, groupByUsers }) => {

  const [aclGroups, setAclGroups] = useState(['Employee', 'HR', 'Candidate']);
  const [acls, setAcls] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [moduleFormData, setModuleFormData] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [editAccessControlItem, setEditAccessControlItem] = useState()

  useEffect(() => {
    setAcls(
      permissions.map(permission => {
        const selectedUserGroup = userGroups.find(item =>
          item.mag_user_id === selectedUser
        );
        return {
          ...permission,
          checked: selectedUserGroup ? selectedUserGroup.mag_acl_ids?.includes(permission.acl_id) : false
        };
      })
    );
  }, [permissions, selectedUser]);

  useEffect(() => {
    getAllAclGroups()
    getPermissionList()
  }, []);

  // useEffect(() => {
  //   setAclGroups(userGroups);
  // }, [userGroups])

  useEffect(() => {
    setAcls(permissions);
  }, [permissions])

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    if (event.target.value) {
      getUserList(event.target.value)
    }
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };
  const handlePermissionChange = (aclId, checked) => {
    setAcls(acls.map(acl => ({
      ...acl,
      checked: acl.acl_id === aclId ? checked : acl.checked
    })));
    setSelectedPermissions((prev) => {
      if (checked) {
        return [...prev, aclId];
      } else {
        return prev.filter(id => id !== aclId);
      }
    });
  };

  const handleSaveChanges = () => {
    savePermissions({ selectedUser, selectedPermissions })
  };

  const addModule = () => {
    setOpenModal(true)
  }

  const handleModulePermissionFormSubmit = () => {
    createModulePermission(moduleFormData, editAccessControlItem.acl_id)
    setOpenModal(false)
  }

  const handleModuleFormChange = (e) => {
    const { name, value } = e.target;
    setModuleFormData({ ...moduleFormData, [name]: value })
  }

  const handleCancel = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    !openModal && setEditAccessControlItem('');
  }, [openModal])

  const editAccessControl = (acl) => {
    setEditAccessControlItem(acl);
    setOpenModal(true)
  }

  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        Access Control Management
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormControl sx={{ width: '50%' }} variant="outlined">
          <InputLabel sx={{ fontWeight: 'bold' }}>Select User Group</InputLabel>
          <Select
            value={selectedGroup}
            onChange={handleGroupChange}
            label="Select User Group"
          >
            {aclGroups.map((group) => (
              <MenuItem key={group} value={group}>
                {group}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedGroup && (<FormControl sx={{ width: '50%' }} variant="outlined">
          <InputLabel sx={{ fontWeight: 'bold' }}>Select User </InputLabel>
          <Select
            value={selectedUser}
            onChange={handleUserChange}
            label="Select User"
          >
            {groupByUsers.map((item) => (
              <MenuItem key={item?.user?.u_id} value={item?.user?.u_id}>
                {item?.user?.u_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>)}
      </Box>

      {(selectedUser && acls.length) ? (<TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              {['Module', 'Type', 'Route', 'API', 'Method', 'Access', 'Action'].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: '#e0e0e0', // Slightly darker gray background color
                    color: '#333', // Dark text color for visibility
                    textAlign: 'center',

                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {acls.map((acl) => (
              <TableRow key={acl.acl_id} sx={{
                color: '#333', // Dark text color for visibility
                textAlign: 'center',

              }}>
                <TableCell sx={{ color: '#333', textAlign: 'center' }}>{acl.acl_module}</TableCell>
                <TableCell sx={{ color: '#333', textAlign: 'center' }}>{acl.acl_type}</TableCell>
                <TableCell sx={{ color: '#333', textAlign: 'center' }}>{acl.acl_route}</TableCell>
                <TableCell sx={{ color: '#333', textAlign: 'center' }}>{acl.acl_api}</TableCell>
                <TableCell sx={{ color: '#333', textAlign: 'center' }}>{acl.acl_method}</TableCell>
                <TableCell sx={{ color: '#333', textAlign: 'center' }}>
                  <Checkbox
                    checked={!!acl.checked}
                    onChange={(e) =>
                      handlePermissionChange(acl.acl_id, e.target.checked)
                    }
                  />
                </TableCell>
                <TableCell>
                  <IconButton aria-label="edit" onClick={() => editAccessControl(acl)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>) : ''}
      {(selectedUser && acls.length) ? (<Box display="flex" justifyContent="space-between">
        {/* <Button variant="contained" style={{ marginTop: '20px' }}
          color="primary" onClick={addModule}>Add Module</Button> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          style={{ marginTop: '20px' }}
        >
          Save Changes
        </Button>
      </Box>) : ''}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box className="modalContent" sx={modalPopUpStyle}
          component="form">
          <h2 className="modalTitle" id="child-modal-title">
            {editAccessControlItem ? 'Edit' : 'Add'} Module
          </h2>
          <form className="modalForm">
            <div className="modalFormItem">
              <label className="label-bold">Module</label>
              <TextField
                placeholder="Module Name"
                name='moduleName'
                defaultValue={editAccessControlItem?.acl_module}
                onChange={handleModuleFormChange}
                required
                fullWidth
              />
            </div>
            <div className="modalFormItem">
              <label className="label-bold">Type</label>
              <TextField
                placeholder="Type"
                name='TypeName'
                defaultValue={editAccessControlItem?.acl_type}
                onChange={handleModuleFormChange}
                required
                fullWidth
              />
            </div>
            <div className="modalFormItem">
              <label className="label-bold">Route</label>
              <TextField
                placeholder="Route"
                name='Route'
                defaultValue={editAccessControlItem?.acl_type}
                onChange={handleModuleFormChange}
                required
                fullWidth
              />
            </div>
            <div className="modalActions">
              <Button className="modalButton" onClick={handleModulePermissionFormSubmit}>
                Change
              </Button>
              <Button className="modalButton" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userGroups: state.permission.userGroups,
  permissions: state.permission.permissions,
  groupByUsers: state.permission.groupByUsers,
})

const mapDispatchToProps = {
  savePermissions,
  getAllAclGroups,
  getPermissionList,
  getUserList,
  createModulePermission
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlManagement);